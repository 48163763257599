<template>
  <div class="user-center">
    <div class="tabs">
      <div class="account" :class="{ user: true, active: activeIndex === '1' }" @click="handleSelect('1')">
        账号信息
      </div>
      <div :class="{ user: true, active: activeIndex === '2' }" @click="handleSelect('2')">
        作品管理
      </div>
    </div>
    <div class="content">
      <UserInfo v-if="activeIndex === '1'" :userInfo="userInfo" />
      <WorksInfo v-if="activeIndex === '2'" />
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <div>请完善您的账号信息，特别是带*标的信息</div>
      <div>完善联系人信息后可进行作品投递</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100px" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UserInfo from './user-info';
import WorksInfo from './works-info';
import ApiService from '@/api/apiService';
import API from '@/api';

export default {
  name: 'user-info',
  data() {
    return {
      activeIndex: '1',
      dialogVisible: false,
      userInfo: null
    };
  },
  components: {
    UserInfo,
    WorksInfo
  },
  watch: {},
  mounted() {
    this.userId = localStorage.getItem('userId');
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      const result = await ApiService.get(API.userInfo + this.userId);
      this.userInfo = { ...result.data };
      this.dialogVisible = !result.data.replenish;
    },

    handleSelect(key) {
      this.activeIndex = key;
      this.getUserInfo();
    }
  }
};
</script>

<style scoped lang="less">
/deep/.el-dialog__wrapper {
  .el-dialog {
    border-radius: 8px;
    .el-dialog__footer {
      text-align: center;
      .dialog-footer {
        .el-button {
          color: #1a2043 !important;
          width: 18vw !important;
          border-color: #eee7d8 !important;
          background-color: #eee7d8 !important;
        }
      }
    }
  }
}

.user-center {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .tabs {
    width: 7vw;
    margin-left: 10.27vw;
    text-align: right;
    font-size: 1.25vw;
    font-weight: 600;
    color: #1a2043;
    line-height: 1.74vw;
    .user,
    .zuopin {
      cursor: pointer;
      margin-bottom: 1.39vw;
      &.active {
        border-left: 4px solid #1a2043;
      }
    }
  }
  .content {
    flex: 1;
    margin: 0 0 4.86vw 4.86vw;
    border-radius: 10px;
    border: 10px solid #eaeaea;
  }
}
</style>

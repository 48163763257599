<template>
  <div class="works-info">
    <div class="title"><span class="line"></span>作品列表</div>
    <div class="work-info" v-for="(workInfo, index) in worksList" :key="index">
      <WorkItem :workInfo="workInfo" @refresh="getWorksList" />
    </div>
    <div class="add-work" @click="toAddWork()">+ 新增作品</div>
  </div>
</template>

<script>
import ApiService from '@/api/apiService';
import API from '@/api';
import WorkItem from './work-item';

export default {
  name: 'works-info',
  data() {
    return {
      worksList: []
    };
  },
  components: {
    WorkItem
  },
  watch: {},
  mounted() {
    this.getWorksList();
  },
  methods: {
    async getWorksList() {
      const result = await ApiService.post(API.worksList, {
        pageIndex: 1,
        pageSize: 100
      });
      this.worksList = result.data.records;
      console.log('result:', this.worksList);
    },
    toAddWork() {
      this.$router.push('/submission');
    }
  }
};
</script>

<style scoped lang="less">
.works-info {
  padding: 2.1vw;
  text-align: left;
  .title {
    margin: 0 0 1.388vw 0.7vw;
    width: 8.33vw;
    text-align: left;
    font-size: 1.25vw;
    font-weight: 500;
    color: #1a2043;
    line-height: 1.74vw;
    .line {
      padding-left: 6px;
      margin-right: 0.7vw;
      height: 1.74vw;
      border: 1px solid #1a2043;
    }
  }
  .work-info {
    margin-bottom: 2vw;
  }
  .add-work {
    cursor: pointer;
    margin: 1.388vw 0 0 12.2vw;
    width: 20vw;
    height: 2.5vw;
    line-height: 2.5vw;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #1a2043;
    font-size: 1.25vw;
    font-weight: 500;
    color: #595959;
  }
}
</style>

<template>
  <div class="user-info">
    <div class="register-info">
      <div class="title"><span class="line"></span>注册信息</div>
      <div class="info">
        <span class="label">注册邮箱</span>
        <span style="marginLeft: 100px">{{ ruleForm.email }}</span>
        <span
          style="marginLeft: 100px; color: red"
          @click="dialogFormVisible = true"
          >修改密码</span
        >
      </div>
    </div>
    <div class="contact-info">
      <div class="title"><span class="line"></span>联系人信息</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓" prop="firstName">
          <el-input v-model="ruleForm.firstName"></el-input>
        </el-form-item>
        <el-form-item label="名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="telephone">
          <el-input
            placeholder="请输入正确的手机号码 (如: 13812341234) "
            v-model="ruleForm.telephone"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input
            placeholder="请输入正确的身份证号 (如: 432123198912103118) "
            v-model="ruleForm.idNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="职业/角色" prop="occupation">
          <el-select
            style="width: 100%"
            v-model="ruleForm.occupation"
            placeholder="请选择"
          >
            <el-option
              v-for="item in occupationOpttions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮编" prop="postCode">
          <el-input
            placeholder="请输入邮编"
            v-model="ruleForm.postCode"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="通讯地址" prop="address">
          <el-select v-model="ruleForm.address" placeholder="请选择通讯地址">
            <el-option
              v-for="item in addressCode"
              :label="item.name"
              :value="item.districtcode"
              :key="item.districtcode"
            ></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="国家/地区" prop="country">
          <el-input
            placeholder="请输入国家/地区"
            v-model="ruleForm.country"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在省份及城市" prop="province">
          <el-input
            placeholder="请输所在省份及城市"
            v-model="ruleForm.province"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="addressDetail">
          <el-input
            placeholder="请输入详细地址"
            v-model="ruleForm.addressDetail"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="save" type="primary" @click="submitForm('ruleForm')"
            >保 存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
      <el-form :model="resetPasswordForm">
        <el-form-item label="邮箱" label-width="120px">
          <div>{{ ruleForm.email }}</div>
        </el-form-item>
        <el-form-item label="新密码" label-width="120px">
          <el-input
            type="password"
            v-model="resetPasswordForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPassword()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiService from "@/api/apiService";
import API from "@/api";

export default {
  name: "user-info",
  props: {
    userInfo: Object,
  },
  data() {
    return {
      occupationOpttions: [
        {
          value: 1,
          label: "在校学生",
        },
        {
          value: 2,
          label: "指导教师",
        },
        {
          value: 3,
          label: "专职设计师",
        },
        {
          value: 4,
          label: "其他",
        },
      ],
      userId: null,
      dialogFormVisible: false,
      addressCode: [],
      resetPasswordForm: {
        id: null,
        password: "",
      },
      ruleForm: {
        id: null,
        email: "",
        firstName: "",
        name: "",
        telephone: "",
        idNumber: "",
        postCode: "",
        addressDetail: "",
        address: "",
        country: "",
        province: "",
        occupation: undefined,
      },
      rules: {
        firstName: [{ required: true, message: "请输入姓", trigger: "blur" }],
        name: [{ required: true, message: "请输入名", trigger: "blur" }],
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        idNumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        addressDetail: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请选择通讯地址", trigger: "change" },
        ],
        country: [
          { required: true, message: "请输入国家/地区", trigger: "blur" },
        ],
        province: [
          {
            required: true,
            message: "请输入所在省份及城市",
            trigger: "blur",
          },
        ],
        occupation: [
          {
            required: true,
            message: "请选择职业/角色",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  watch: {
    userInfo() {
      this.ruleForm = { ...this.userInfo };
    },
  },
  mounted() {
    // ApiService.get(API.address + "0").then((res) => {
    //   this.addressCode = res.data.result;
    //   console.log("address:", this.addressCode);
    // });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs["ruleForm"].validate(async (valid) => {
            if (valid) {
              // console.log("form:", this.ruleForm);
              await ApiService.post(API.updateUserInfo, {
                ...this.ruleForm,
              });
              this.$message.success("提交成功");
              this.getUserInfo();
              // console.log("result:", result);
              // this.$router.push("/home");
            } else {
              this.$message.error("请输入完整信息");
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },

    // 修改密码
    async resetPassword() {
      if (!this.resetPasswordForm.password) {
        this.$message.error("请输入新密码");
        return;
      }
      await ApiService.post(API.updateUserInfo, {
        ...this.resetPasswordForm,
        id: this.userInfo.id,
      });
      this.$message.success("密码修改成功");
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.user-info {
  text-align: left;
  .title {
    margin: 0 0 1.4vw 0.7vw;
    width: 8.33vw;
    text-align: left;
    font-size: 1.25vw;
    font-weight: 500;
    color: #1a2043;
    line-height: 1.67vw;
    .line {
      padding-left: 0.42vw;
      margin-right: 0.7vw;
      height: 1.67vw;
      border: 1px solid #1a2043;
    }
  }
  .register-info {
    padding: 2.08vw;
    border-bottom: 10px solid #eaeaea;
    .info {
      .label {
        display: inline-block;
        width: 7vw;
        text-align: right;
      }
    }
  }
  .contact-info {
    padding: 2.08vw;
  }
  .save {
    color: #000;
    width: 100%;
    background: #eee7d8;
    border: unset;
    &:hover {
      color: #fff;
      background: #b5a687;
    }
  }
}
</style>

<template>
  <div class="work-item">
    <div class="image">
      <el-image v-if="workInfo.imgUrl" fit="cover" :src="workInfo.imgUrl" @click="dialogShowWorkVisible = true"></el-image>
    </div>
    <div class="content">
      <div class="work">
        <div class="name">
          <div class="left">作品名称</div>
          <div class="text">{{ workInfo.name }}</div>
        </div>
        <div class="author">
          <div class="left">作者</div>
          <div class="text">
            <div v-for="(author, index) in workInfo.teamMembers" :key="'author' + index">
              {{ author.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!workInfo.confirm" class="submit" @click="dialogVisible = true">
        提交
      </div>
      <el-image v-if="workInfo.confirm" class="tijiao" fit="fill" :src="require('@/static/images/tijiao.png')" alt=""></el-image>
    </div>
    <div class="operator">
      <span v-if="!workInfo.confirm" @click="toUpdateWork()">修改</span>
      <span @click="dialogDeleteVisible = true">删除</span>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible">
      <div>作品提交后无法修改，是否确认提交？</div>
      <div class="aggrement">
        本人已详细阅读《赛事规则》，理解且同意全部条款
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitWork()">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除作品" :visible.sync="dialogDeleteVisible">
      <div>确定要删除作品？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteWork()">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog class="show-work" title="" :visible.sync="dialogShowWorkVisible">
      <div class="work-title">浙江云和木玩创意设计大赛 ｜ 作品报名表</div>
      <div class="work-content">
        <div class="sub-content">
          <div class="title">1. 作品名称</div>
          <div class="text">{{ workInfo.name }}</div>
        </div>
        <div class="sub-content">
          <div class="title">2. 团队成员</div>
          <div class="text">
            <div class="member" v-for="(memberItem, index) in workInfo.teamMembers" :key="'member' + index">
              <span style="marginRight: 40px">姓名：{{ memberItem.name }}</span>
              <span style="marginRight: 40px">联系方式：{{ memberItem.contact }}</span>
              <span>院校/单位：{{ memberItem.school }}</span>
            </div>
          </div>
        </div>
        <div class="sub-content">
          <div class="title">3. 作品版面</div>
          <!-- <div class=""> -->
          <el-image v-if="workInfo.imgUrl" fit="cover" :src="workInfo.imgUrl" @click="dialogShowWorkVisible = true"></el-image>
          <!-- </div> -->
        </div>
        <div class="sub-content">
          <div class="title">4. 设计说明</div>
          <div class="text">{{ workInfo.title }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiService from '@/api/apiService';
import API from '@/api';

export default {
  name: 'work-item',
  props: {
    workInfo: Object,
    refresh: Function
  },
  data() {
    return {
      dialogVisible: false,
      dialogDeleteVisible: false,
      dialogShowWorkVisible: false
    };
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {
    async submitWork() {
      await ApiService.post(API.updateWork, {
        ...this.workInfo,
        confirm: true
      });
      this.dialogVisible = false;
      this.$emit('refresh');
      this.$message.success('提交成功');
    },
    async deleteWork() {
      await ApiService.post(API.deleteWork + this.workInfo.id);
      this.$message.success('删除成功');
      this.dialogDeleteVisible = false;
      this.$emit('refresh');
    },
    toUpdateWork() {
      this.$router.push('/submission' + `?id=${this.workInfo.id}`);
    }
  }
};
</script>

<style scoped lang="less">
.work-item {
  height: 13vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .el-image {
    border-radius: 6px;
  }

  /deep/.show-work {
    .el-dialog {
      width: 50vw !important;
    }
    .work-title {
      font-size: 1.25vw;
      font-weight: 500;
      color: #1a2043;
      margin-bottom: 16px;
    }
    .work-content {
      text-align: left;
      .sub-content {
        margin-bottom: 16px;
        .title {
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: 500;
          color: #1a2043;
        }
        .text {
          line-height: 25px;
        }
      }
    }
  }

  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 8px;
      width: 30vw;
      .el-dialog__body {
        text-align: center;
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button {
            color: #1a2043 !important;
            width: unset !important;
            border-color: #eee7d8 !important;
            background-color: #eee7d8 !important;
          }
        }
      }
    }
  }

  .text {
    line-height: 1.667vw;
  }
  .el-image {
    width: 9.236vw;
    height: 13vw;
  }
  .content {
    // width: 200px;
    margin: 0 3vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    .name,
    .author {
      display: flex;
      justify-content: center;
      margin-bottom: 1vw;
    }
    .submit {
      background-color: #1a2043;
      width: 6vw;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
    }
    .tijiao {
      width: 5.277vw;
      height: auto;
    }
    .left {
      display: inline-block;
      width: 6vw;
      font-size: 1.1vw;
      font-weight: 500;
      color: #595959;
    }
    .text {
      display: inline-block;
      width: 10vw;
      line-height: 1.4vw;
    }
  }
  .operator {
    color: red;
    span {
      cursor: pointer;
      margin-left: 1vw;
    }
  }
  .aggrement {
    margin-top: 1.4vw;
    font-size: 0.9vw;
    font-weight: 300;
    color: #e25151;
    line-height: 1.4vw;
  }
}
</style>
